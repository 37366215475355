.buscador {
  max-height: fit-content;
}

.buscador-head {
  width: 100%;
  border-radius: 25px 25px 0px 0px;
  padding: 15px 32px;
  gap: 60px;
  align-items: center;
  display: flex;
}

.buscador-head-text {
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-left: 25px;
  /* identical to box height */
  color: #4a5054;
}

.buscador-centro {
  width: 100%;
  height: 100%;
  padding: 4px 30px;
  background: #6e6e6c;
  border-radius: 0px 0px 25px 25px;
  display: flex;
  align-items: center;
}

.buscador-centro-checks {
  display: flex;
  gap: 35px;
  padding: 4px 2px 20px 25px;
  width: 100%;
}

.buscador-centro-checks-text {
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 30px;
  /* identical to box height */

  color: #ffffff;
}

.buscador-centro-ubicacion {
  display: grid;
  grid-template-columns: 10% auto 0%;
  align-items: center;
  /* align-items: center;
    width: 100%;
    margin-bottom: 15px;
    margin-left: 20px; */
}

.buscador-centro-fechas {
  display: flex;
  margin-right: 18px;
  margin-left: 9px;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
}

.buscador-centro-ubicacion-licono {
  height: 20px;
}

.buscador-centro-ubicacion-input-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 70%; */
}

.buscador-centro-ubicacion-input {
  height: 25px;
  width: 100%;
}

.buscador-centro-date-input {
  height: 25px;
  cursor: pointer;
}

.buscador-centro-ubicacion-icono {
  position: relative;
  left: -20px;
  height: 15px;
}

.buscador-centro-pasajeros {
  display: grid;
  grid-template-columns: 10% auto;
  row-gap: 10px;
  align-items: center;
  justify-items: start;
  width: 100%;
}

.buscador-centro-btn-buscar {
  width: 95%;
  background: #fecf50;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  margin-top: 18px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 33px;
  cursor: pointer;
  color: #fff;
}

.buscador-listado-destinos {
  position: absolute;
  z-index: 10;
  margin-left: 35px;
  margin-top: 30px;
  width: 200px;
}

.buscador-listado-destinos-opciones {
  background-color: #ffffff;
  border: 1px solid black;
  width: 100%;
  cursor: pointer;
}
