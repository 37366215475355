.img-carga {
  animation: rotacion-8-etapas 3s linear infinite;
  margin-bottom: 30px;

}

@keyframes rotacion-8-etapas {
  0% {
    transform: rotate(360deg);
  }
  12.5% {
    transform: rotate(315deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(225deg);
  }
  50% {
    transform: rotate(180deg);
  }
  62.5% {
    transform: rotate(135deg);
  }
  75% {
    transform: rotate(90deg);
  }
  87.5% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
