.footerBackground {
  padding-bottom: 1rem;
  padding-top: 5rem;
  background-color: #eff0ef; /* Light background for the footer */
  position: relative; /* Necessary for positioning the custom shape */
  overflow: hidden; /* Ensures the shape stays within the footer */
  clip-path: polygon(
    50% 8%,
    100% 0%,
    100% 100%,
    100% 75%,
    100% 100%,
    0% 100%,
    0% 0%
  );
}
