.head-div{
    width: 100%;
    /* background-color: rgba(123, 122, 116, 1); */
    /* position: absolute;
    left: 0px;
    top: 0px; */
    background: #7B7A74;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.head-logo{
    width: 180px;
}

.head-div-mitad-der{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.head-opciones{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 8px;
    height: 100%;
    width: 100px;
    cursor: pointer;
}

.head-opciones-icono{
    height: 30px;
}

.head-opciones-text{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #FECF50;
}