.centro-carga {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.span-carga {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 33px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}

.centro-vuelos {
  display: flex;
  background: #f2f2f2;
  justify-content: center;
  gap: 30px;
  padding-top: 30px;
}

.centro-vuelos-der {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 800px;
}
