span {
  font-family: "DM Sans";
  font-style: normal;
  color: #4a5054;
}

.opcion-vuelo {
  display: flex;
  flex-direction: column;
  background: #6e6e6c;
  border-radius: 28px;
  padding: 25px;
}

.opcion-vuelo-sup {
  width: 100%;
  background: #fecf50;
  /* border-radius: 20px 20px 0px 0px; */
  display: flex;
  gap: 60px;
  align-items: center;
}

.opcion-vuelo-sup-texto {
  display: flex;
  margin-left: 10px;
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
}

.opcion-vuelo-inf {
  width: 100%;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
}

.opcion-vuelo-inf-izq {
  display: flex;
  flex-direction: column;
}

.opcion-vuelo-segmento {
  height: 65px;
  display: flex;
  gap: 15px;
  margin-top: 5px;
}

.opcion-vuelo-segmento-check {
  max-width: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.opcion-vuelo-segmento-aerolinea {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.opcion-vuelo-segmento-aerolinea-img {
  width: auto;
  max-width: 80px;
}
.opcion-vuelo-segmento-origen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.opcion-vuelo-segmento-origen-code {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #000000;
}
.opcion-vuelo-segmento-paradas {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.opcion-vuelo-segmento-destino {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.opcion-vuelo-segmento-destino-code {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #000000;
}
.opcion-vuelo-segmento-duracion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.opcion-vuelo-segmento-equipaje {
  display: flex;
  gap: 5px;
  align-items: flex-end;
}

.opcion-vuelo-segmento-equipaje-div {
  height: 90%;
}

.opcion-vuelo-segmento-equipaje-img {
  width: 20px;
}

.opcion-vuelo-segmento-info-masinfo {
  cursor: pointer;
  width: 20px;
}

.opcion-vuelo-inf-der {
  min-height: 200px;
  width: 25%;
}

.opcion-vuelo-precio {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.opcion-vuelo-precio-btn {
  width: 200px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fecf50;
  border-radius: 7px;
  margin-top: 10px;
  cursor: pointer;
}

.opcion-vuelo-precio-btn-span {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
}

.opcion-vuelo-precio-cantpasa {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.opcion-vuelo-precio-txtsup {
  margin-top: 10px;
}

.opcion-vuelo-precio-valor {
  margin-top: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  color: #4a5054;
}
