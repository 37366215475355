@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@layer base {
  .button {
    @apply w-48 py-2 rounded;
  }
  .input {
    @apply py-1 pl-4 pr-0 bg-lightGray text-sm text-gray rounded-md transition-all;
  }
  .tableGrid {
    @apply grid grid-flow-row grid-cols-23;
  }
  .addPackInput {
    @apply py-2 px-4 pr-0 rounded border text-sm border-lightGray3 text-gray transition-all;
  }
  .photo {
    @apply bg-lightGray3 w-full rounded;
  }
  .detailTable {
    @apply border-2 border-gray2 rounded-lg
  }
  .datailTableRow {
    @apply w-full flex justify-between items-center px-4 py-2 rounded
  }
  .tableCell {
    @apply m-auto h-full w-full font-medium text-center py-1 border-gray2
  }
  .pasajerosCell {
    @apply tableCell col-span-2 text-left pl-4
  }
  .reservaButton {
    @apply py-[2px] px-4 w-40 border-2 border-gray text-gray h-fit rounded hover:shadow-md transition-shadow
  }
}

* {
  /* border: 1px solid red; */
}
/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
